
import img1 from '@/assets/images/icon_1.png'
import img2 from '@/assets/images/icon_2.png'
import img3 from '@/assets/images/icon_3.png'
import img4 from '@/assets/images/icon_4.png'
import img5 from '@/assets/images/icon_5.png'
import img6 from '@/assets/images/icon_6.png'
import img7 from '@/assets/images/icon_7.png'
import imgPlace from '@/assets/images/icon_place.png'
import imgTime from '@/assets/images/icon_time1.png'
import imgZan from '@/assets/images/icon_zan.png'
import imgCang from '@/assets/images/icon_cang.png'
import imgCangTrue from '@/assets/images/icon_cangTrue.png'

export const imgObj = {
  icon1: img1,
  icon2: img2,
  icon3: img3,
  icon4: img4,
  icon5: img5,
  icon6: img6,
  icon7: img7,
  imgPlace:imgPlace,
  imgTime:imgTime,
  imgZan:imgZan,
  imgCang:imgCang,
  imgCangTrue:imgCangTrue}